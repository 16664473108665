import React from "react";
import Helmet from 'react-helmet';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'gatsby'
import ReactMarkdown from "react-markdown"
import ReactReadMoreReadLess from "react-read-more-read-less"

/**
 * Layouts
 */
import Layout from '@layouts/default';
import Video from '@components/video/video';
import NoVideo from '@components/video/without-video';
import Relative from '@components/relative/relative';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import About from '@components/about/about';
import SeamlessExperience from '@components/seamless-experience/seamless-experience';
import PropertyControl from '@components/property-control/property-control';
import Reviews from '@components/reviews/reviews';
import AreYouLooking from '@components/book-video/are-you-looking';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import PropertySlider from '@components/property-slider/gallery-slider';
import Map from '@components/map/map';
import FeaturedProperty from '@components/featured-property/featured-property';
import { graphql } from 'gatsby'
import get from 'lodash/get'
import CalendlyCTAOne from '@components/calendly/global-cta-one';
import SEO from "../components/seo";
import OfficeCardImg from '@components/cards/office-card';


class AreaGuideDetailsTemplate extends React.Component {

  render() {

    const Page = get(this, 'props.data.strapiAreaGuides')
    const Person = get(this, 'props.data.strapiTeams')
    const Calendly = get(this, 'props.data.strapiGlobalConfig.Calendly_Link')

    const url = typeof window !== 'undefined' ? window.location.href : '';


    if(Page.Upload_Images == "" )
    {
      var head_col = 12;
    }
    else
    {
      var head_col = 7;    
    }


    if(Person != null) {
    if((Person.Calendly_Link == null) || (Person.Calendly_Link == "")) {
      var video_call = Calendly
    }
    else {
      var video_call = Person.Calendly_Link
    }
    }
    else
    {
      var video_call = Calendly      
    }


    return (
      <Layout>
        <Helmet 
          bodyAttributes={{
            class: 'static templates-area-guide-template-js area-guide-details'
          }}
        />
        <SEO title={Page.Meta_Title} description={Page.Meta_Description} />
        <div className="page-body">
        <Relative className="top-banner">
          <Container className="property-intro" fluid>
            <Row>
              <Col lg={{span: `${head_col}`}} className="align-self-center top-banner-1">

              {Page.Upload_Video != null && Page.Upload_Video != "#" &&
                <Video 
                  image={Page.Tile_Image} 
                  video={Page.Upload_Video.publicURL} 
                  height="570px" 
                  imgsize="950x650"
                  contain={true}
                />
              }
              {Page.Embed_Video_URL != null && Page.Embed_Video_URL != "#" &&
                <Video 
                  image={Page.Tile_Image} 
                  video={Page.Upload_Video.publicURL} 
                  height="570px" 
                  imgsize="950x650"
                  contain={true}
                />
              }
              {Page.Upload_Video == null && Page.Embed_Video_URL == null &&
                <NoVideo 
                  image={Page.Tile_Image} 
                  height="570px" 
                  imgsize="950x650"
                  contain={true}
                />
              }


              </Col>
              <Col lg={{span: 5}} className="align-self-center area-guide-gallery">
              {Page.Upload_Images != "" &&
                <PropertySlider slides={Page.Upload_Images} />
              }
              </Col>
            </Row>
          </Container>
        </Relative>

      <Container>
        <div className="post-content">
          <h6>{Page.Title}</h6>
          <div className="post-content__inner area-guide-intro">
              <div className="post-content__left">
                <h3>{Page.Detail_Page_H1}</h3>
                  <p className="larger">
                    <ReactMarkdown source={Page.Detail_Page_Intro} />
                  </p>
              </div>
              <div className="post-content__right speak-with">
                <CalendlyCTAOne url={video_call}/>

                {Person != null &&
                <Row>
                  <Col className="col-auto">
                    <img src={Person.Image.publicURL} alt="avatar" className="intro-avatar"/>
                  </Col>
                  <Col>
                    <p>Speak with {Person.Name.split(" ")[0]} on<br/> <a href={`tel:+${Person.Phone}`}>{Person.Phone}</a> or <Link to="/contact">email</Link> </p>
                  </Col>
                </Row>
                }

              </div>
          </div>
        </div>
      </Container>


      <Map />

      {Page.Content != "" &&
      <Container>
        <div className="post-content property-details area-guide-content">
          <Row className="post-content__inner">
            <Col lg={7}>
                {Page.Content.map((Content, i) => {
                  return (
                    <div className={`area-guide-content-${i}`}>
                      <ReactMarkdown source={Content.Content} />
                    </div>
                  )
                })}
              </Col>
              <Col lg={{span: 4, offset: 1}} className="align-self-start sidebar-sticky">
                {Page.Office != null &&
                <Card>
                  <Row className="align-items-center">
                    <Col md={6} lg={12}>
                      <OfficeCardImg url={Page.Office.Office_Image} />
                    </Col>
                    <Col md={6} lg={12}>
                      <Card.Body>
                        <h6><Link to={`/contact-west-yorkshire-estate-agents/${Page.Office.URL}`}>{Page.Office.Office_Name}</Link></h6>
                        <p><ReactMarkdown source={Page.Office.Address} /></p>
                        <p><a href={`tel:+${Page.Office.Phone}`}>{Page.Office.Phone}</a> / <Link to="/contact">Email Office</Link></p>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
                }
                <Card>
                  <Card.Body>
                    <div className="socials">
                      <h5>Share this Property</h5>
                      <Link to="/contact">
                        <img src="/images/icon-email.png" alt="email" width="44" height="44"/>
                      </Link>
                      <a href={`//www.facebook.com/sharer/sharer.php?u=${url}`} rel="noreferrer" target="_blank">
                        <img src="/images/icon-facebook.png" alt="facebook" width="44" height="44"/>
                      </a>
                      <a href={`//twitter.com/share?text=${Page.Detail_Page_H1.replace(" ", "+")}&url=${url}&via=holroydsuk`} rel="noreferrer" target="_blank">
                        <img src="/images/icon-twitter.png" alt="twitter" width="44" height="44"/>
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
          </Row>
        </div>
      </Container>
      }

      <AreYouLooking /> 

      <div className="area-guide-featured-property">
        <FeaturedProperty Title={Page.Title} area={Page.Title}/>
      </div>

        
      <Breadcrumbs Parent_Label="About" Parent_URL="about" Parent_1_Label="Living in West Yorkshire" Parent_1_URL="../about/living-in-west-yorkshire"  Page_1={Page.Title}/>
      </div>
      </Layout>
    )
  }
}

export default AreaGuideDetailsTemplate;


export const pageQuery = graphql`
  query AreaGuideDetailsTemplateSlug($slug: String!) {

    strapiAreaGuides(URL: { eq: $slug }) {
    Meta_Description
    Meta_Title
    Title
    Tile_Image {
        internal {
          description
        }
        publicURL
    }
    Embed_Video_URL
    Upload_Video {
      publicURL
    }
    Detail_Page_H1
    Detail_Page_Intro
    Short_Description
    Upload_Images {
      url
    }
    Speak_with {
      Name
      Email
      Phone
      Image {
        publicURL
      }
    }
    Content {
      Content
      Show_Readmore
    }
    Office {
      Address
      URL
      Email
      Office_Name
      Phone
      Office_Image {
        internal {
          description
        }
        publicURL
      }
    }
    Upload_Images {
      url
    }
  }

    strapiTeams(area_guides: {elemMatch: {URL: {eq: $slug}}}) {
    id
    Name
    Calendly_Link
    Email
    Phone
    Image {
      publicURL
    }
  }


  strapiGlobalConfig {
    Calendly_Link
  }



  }
`