import React from "react";
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import ReactMarkdown from "react-markdown"


/**
 * Components
 */
import Video from '@components/video/video';
import NoVideo from '@components/video/without-video';
import ModalVideo from '@components/video/video-modal';
import Relative from '@components/relative/relative';
import Holder from '@components/holder/holder';

const SeamlessExperience = (props,{className}) => {
  return (
    <Relative background="#fff" className={classNames(className)}>
      <Container className="overflow-hidden">
        <Row>
          <Col 
            lg={6} 
            className="is-static"
            data-sal="fade"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
          {props.Upload_Video != null && props.Upload_Video != "#" &&
            <Video 
              width="55.625%" 
              height="600px" 
              align="left" 
              image={props.Image} 
              imgsize={props.size}
              video={props.Upload_Video.publicURL} 
            />
              }
              {props.Embed_Video_URL != null && props.Embed_Video_URL != "#" &&
                <ModalVideo 
                  width="55.625%" 
                  height="600px" 
                  align="left" 
                  image={props.Image} 
                  imgsize={props.size}
                  video={props.Embed_Video_URL} 
                  name={props.Title}
                />
              }
              {props.Upload_Video == null && props.Embed_Video_URL == null &&
                <NoVideo 
                  width="55.625%" 
                  height="600px" 
                  align="left" 
                  image={props.Image} 
                  imgsize={props.size}
                />
              }
          </Col>
          <Col 
            lg={{span: 5, offset: 1}} 
            className="align-self-center text-box text pl-md-5"
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h4>{props.Title}</h4>
            <p>
              <ReactMarkdown source={props.Content} />
            </p>
            {props.CTA_URL != null &&
            <Holder maxWidth="271px" mobile={false}>
              <Link to={`/${props.CTA_URL}`} className="btn btn-outline-primary">{props.CTA_Label}</Link>
            </Holder>
            }
          </Col>
        </Row>
      </Container>
    </Relative>
  )
}

export default SeamlessExperience;
