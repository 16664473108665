import React, {useState} from "react";
import Img from "gatsby-image"
import { Card } from 'react-bootstrap';

//GGFX
import {importTransformedIamges, getImageTransformedURL} from '@components/common/site/image-transformer-client';

const GallerySliderImg = (props) => {
  
  const [url, setUrl] = useState('')
  const image_url = props.url.replace("File ","").replace('"','').replace('"','').replace(/^.*\/\/[^\/]+/, '').replace(/^\/|\/$/g, '');  

    getImageTransformedURL({
        "srcPath": image_url, 
        //output format 
        "format": 'webp',
        //transformation size
        "transform": '1600x1200'
      }).then((result) => {
        setUrl(result)
       console.log(result);

      }).catch(error => console.log('ggfx image error', error));

    return (
    <>
      <Card.Img variant="top" className="d-block w-100 gallery-slide" src={url} alt="store" />
    </>
    );
}

export default GallerySliderImg;
