import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'gatsby';
import axios from 'axios';

/**
 * Components
 */
import Property from '@components/property/property';

const FeaturedProperty = (props, {className}) => {

  const [propItems, setPropItems] = useState(false)

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      setPropItems(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    let officeId = props.officeId ?? "";
    let area = props.area ?? "";
    let url = "https://holroyds-strapi-dev.q.starberry.com/properties?search_type=sales&_limit=3&_sort=createdAt:DESC&published=true"; // TODO: base URL supposed to be from .env

    if (area) {
      url = url + "&display_address_contains=" + area;
    }
    if (officeId) {
      url = url + "&office_id=" + officeId;
    }

    getitems(url)
  },[])

  return (
    <div className={classNames('overflow-hidden featured-property', className)}>
      <div className="property-recommend">
        <Container>
          <h4 className="text-md-center">Featured Properties in {props.Title}</h4>
          <p className="text-md-center">Want to buy or rent? Search <Link to="">Property for Sale in {props.Title}</Link> or <Link to="">Property for Rent in {props.Title}</Link></p>
          <Slider
            slidesToShow={3}
            responsive={[
              {
                breakpoint: 980,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000,
                  nextArrow: <span aria-hidden="true" class="carousel-control-next-icon"></span>,
                  prevArrow: <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                }
              }
            ]}
          >
            {propItems && propItems.map(hit => {
              let imageName = ""
              if (hit.images && "" !== hit.images) {
                imageName = hit.images[0]
              }

              return (
                <Property property={{
                  image: imageName,
                  price: `£${hit.price.toLocaleString()}`,
                  location: hit.display_address,
                  description: hit.title,
                  itemUrl: `/property-detail/${hit.slug}-${hit.id}`
                  }}
                />
              )
            })}
          </Slider>
        </Container>
      </div>
    </div>
  )
}

export default FeaturedProperty;
